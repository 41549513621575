import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuFa } from './AsideMenuFa'
import { getPermissao, getRole, getRoles } from '../../../../util'
import { Mensagem } from '../../../../app/modules/portal/Partial/Mensagem'
import { useEffect, useRef } from 'react'
import { tipoPerfil, tipoPermissao } from '../../../../app/modules/portal/tipos/tipos'

export function AsideMenuMain() {


  useEffect(() => {
      document.querySelectorAll('.aside-menu a').forEach(element => {
        element.addEventListener("click", function (event) {
          event.preventDefault();
          var href = element.getAttribute('href');
          (window.location as any).href = href;
        });
      });

      
    const w: any = window;
    const isDev = w?.cnf?.isDev == true;
    if (isDev) {
      const root: any = document.getElementById('root');
      root.style = "background-color: #146902";
    }

  }, []);

  const intl = useIntl();
  return (
    <>
      {/* 
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/files/fil025.svg'
        title={"Meus arquivos"}
        fontIcon='bi-app-indicator'
      />

*/}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Principal</span>
        </div>
      </div>


      {getPermissao(tipoPermissao.criar_proposta) &&
        <AsideMenuItem icon='award' to='/propostas' title='Propostas' fontIcon='bi' />
      }


      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Administração</span>
        </div>
      </div>

      {getPermissao(tipoPermissao.criar_revendedor) &&
        <AsideMenuFa to='/empresa'
          icon='home'
          title='Empresas' hasBullet={true} />
      }

      {getPermissao(tipoPermissao.listar_usuario) &&
        <AsideMenuFa
          icon='user'
          to='/usuarios' title='Usuários'
          hasBullet={true} />}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Opções</span>
        </div>
      </div>
        <AsideMenuFa
          to='/alterarsenha'
          title='Alterar Senha'
          icon='key'
        />

      <AsideMenuItem
        to='/logout'
        title='Sair'
        icon='/media/icons/duotune/arrows/arr060.svg'
        fontIcon='bi-person'
      >
      </AsideMenuItem>


      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
