import { Button, Col, Row } from "react-bootstrap-v5"
import { MInput } from "../../../components/MInput/MInput"
import { validar } from "../../services/Validacoes";
import { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../components/MConfig";
import { fire, getPermissao } from "../../../../util";
import { paging } from "../../../components/MPaging/MPaging";
import { Filtro } from "../Partial/Filtro";
import { tipoPerfil, tipoPermissao } from "../tipos/tipos";

export const FiltroPropostas = props => {


    return <>
        <Filtro novo={props.novo} chamada="filtrar_proposta" urlDownload="/proposta/DownloadRelatorio">
            <Row>
                <Col className="col-md-6 col-12">
                    <MInput
                        id="termoDaBusca"
                        type="text"
                        title="Nome ou CPF"
                    />
                </Col>
                <Col className="col-md-3">
                    <MInput
                        type="date"
                        id="DataCadastroInicio"
                        title="Periodo Inicio" />
                </Col>
                <Col className="col-md-3">
                    <MInput
                        id="DataCadastroFim"
                        type="date"
                        title="Periodo" />
                </Col>
                <Col className="col-md-4 col-12">
                    <MInput
                        id="statusId"
                        type="selectCheckbox"
                        title="Status"
                        data={props.status}
                        default="Todos"
                        onResult={x => x.map(q => ({ value: q.id, text: q.nome }))}
                    />
                </Col>
                {!getPermissao(null,tipoPerfil.Vendedor) &&
                    <>
                        <Col className="col-md-4 col-12">
                            <MInput
                                id="revendedorId"
                                type="selectSearch"
                                title="Empresa"
                                placeholder="Digite o nome da empresa..."
                                url={API_URL + "/revendedor/ListarRevendedores"} params={v => ({ TermoDaBusca: v })}
                                onResult={res => res?.resultado?.map(z => ({ value: z.id, text: z.nome }))}
                            />
                        </Col>
                        <Col className="col-md-4 col-12">
                            <MInput
                                id="usuarioId"
                                type="selectSearch"
                                title="Usuario"
                                placeholder="Digite o nome do usuario..."
                                url={API_URL + "/Usuario/ListarUsuarios"} params={v => ({ TermoDaBusca: v })}
                                onResult={res => res?.resultado?.map(z => ({ value: z.id, text: z.nome }))}
                            />
                        </Col>
                    </>
                }
                <Col className="col-md-4">
                    <MInput
                        id="produtoId"
                        type="select"
                        title="Produto"
                        data={props.produtos}
                        default="Todos"
                        onResult={res => res.map(z => ({ value: z.id, text: z.nome }))}
                    />
                </Col>
            </Row>
        </Filtro>
    </>
}