export class tipoPermissao {    
    public static readonly criar_proposta: string = "criar_proposta";
    public static readonly admin: string = "admin";
    public static readonly criar_usuario: string = "criar_usuario";
    public static readonly criar_revendedor: string = "criar_revendedor";
    public static readonly visualizar_proposta: string = "visualizar_proposta";
    public static readonly inserir_documentos: string = "inserir_documentos";
    public static readonly copiar_link_pagamento: string = "copiar_link_pagamento";
    public static readonly reenviar_link_pagamento: string = "reenviar_link_pagamento";
    public static readonly cancelar_proposta: string = "cancelar_proposta";
    public static readonly relatorio_comissionamento: string = "relatorio_comissionamento";
    public static readonly acesso_total: string = "acesso_total";
    public static readonly listar_usuario: string = "listar_usuario";
}

export class tipoPerfil {
    public static readonly Vendedor: string = "Vendedor";
    public static readonly Backoffice: string = "Backoffice";
    public static readonly Supervisor: string = "Supervisor";
    public static readonly Gerente: string = "Gerente";
    public static readonly Master: string = "Master";
    public static readonly AdminBackOffice: string = "Admin BackOffice";
    public static readonly AdminMaster: string = "Admin Master";
    public static readonly Admin: string = "Admin";    
}