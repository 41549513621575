import * as Yup from 'yup';
import { Button, Col, Row } from "react-bootstrap-v5";
import { MInput } from "../../../components/MInput/MInput";
import { pesquisacep } from "../../services/ViaCep";
import { validar } from "../../services/Validacoes";
import { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../components/MConfig";
import { getAxios } from "../../../components/net";
import { FromDataForm, FromFormReference, ValidateForm, corColunaStatus, fire, stringToData, toData, toFormValues, waitForElement } from "../../../../util";
import Swal from "sweetalert2";
import { MModal } from "../../../components/MModal/MModal";
import { AnexosProposta } from './AnexosProposta';
import { HistoricoProposta } from './HistoricoProposta';
import { listaEstados, tipoEstadoCivil } from '../tipos/tiposJs';

export const PropostaCadastro = props => {

    const modal = useRef();
    const urlOrgao = API_URL + "/cadastrosdb/GetOrgaoSiape"
    const [orgao, setOrgao] = useState(0);
    const [opcaoSelecao, setOpcaoSelecao] = useState([]);
    const [produto, setProduto] = useState(0);
    const [anexos, setarAnexos] = useState([]);
    const [dados, setDados] = useState([]);
    const [visualizar, setVisualizar] = useState(false);
    const [usuario, setUsuario] = useState();
    const [VendedorUsuarioId, setUsuarioId] = useState();
    const [RevendedorId, setVendedorId] = useState();

    //Variar conforme acesso
    //const VendedorUsuarioId = 1;
    //const RevendedorId = 2;
    const StatusId = 1;
    const CodEmpresa = 1;

    var formRef = useRef();
    var modalSelecaoMatricula = useRef();
    var nomeClienteRef = useRef();

    const normalizaTelefone = e => {
        var tam = validar.clearMask(e.target.value)?.length ?? 0;
        e.target.value = validar.mask(e.target.value, (tam == 10 ? "(99)9999-9999" : "(99)99999-9999"));
    }
    
    const pesquisacepproposta = e =>{
        e = validar.mask(e,'99999-999'); 
        pesquisacep(e);
    }

    const recarregarProposta = () => {
        carregarProposta(dados);
    }

    const isProdutoEspecial = e => {
        return isUnaRh(e) || isAAspa(e);
    }

    const isUnaRh = e => {
        return e == 7
    }
    const isAAspa = e => {
        return e == 6
    }
 
    const gerarUrlGeolocalizacao = (latitude, longitude) => {
        return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    };

    const resetFormulario = cpf => {
        const emptyData = {
            NomeCliente: '',
            EstadoCivil: '',
            Cpf: validar.mask(cpf, "999.999.999-99"), 
            Sexo: '',
            NomeMae: '',
            Email: '',
            Telefone: '',
            Logradouro: '',
            Bairro: '',
            Cep: '',
            Cidade: '',
            Uf: '',
            Complemento: '',
            EndNumero: '',
            DataNascimento: null,
            Matricula: '',
            InstituidorMatricula: '',
            Orgao: '',
            CodigoOrgao: '',
            DocIdentidade: '',
            DocIdentidadeUf: '',
            DocIdentidadeOrgEmissor: '',
            docIdentidadeDataEmissao: null
        };
        toFormValues(formRef, emptyData);
        //preencheFormulario(emptyData);
    }

    const preencheFormulario = data => {
        data = { ...data };
        //DADOS COMUNS       
        data.dataNascimento = toData(data.dataNascimento);
        data.docIdentidadeDataEmissao = toData(data.docIdentidadeDataEmissao);
        data.sexo = data?.sexo == "F" || data?.sexo == "2" || data?.sexo == "Feminino" ? "Feminino" : "Masculino"; //HACK
        data.cpf = validar.mask(data.cpf, "999.999.999-99"); 
        data.uf_text = data.uf;

        //SIAPE        
        data.orgao_text = data.orgao;
        data.orgao = data.codigoOrgao;
        setOrgao(data.codigoOrgao);

        setarAnexos(data.propostaAnexos);
        toFormValues(formRef, data);
    }

    
    function identifyAndDecodeBase64(base64String) {
        try {
            // Decodificar a string base64
            let decodedString = atob(base64String);
            return decodedString;
        } catch (e) {
            console.error("Erro ao decodificar a string base64: ", e);
            return null;
        }
    }

    const visualizarAnexo = (idx,historico = false) => {
        
        var url = API_URL + '/proposta/BuscarAnexo/' + idx;
        if(historico) url = API_URL + '/proposta/BuscarAnexoHistorico/' + idx;

        window.showWait(true);
        getAxios().get(url).then(res=>{
            
            let e = res.data;
            if (e) {

                let base64;
    
                if (typeof e !== "string") {
                    console.error("Formato de imagem não suportado");
                    window.showWait(false);
                    return;
                }
    
                if (e.startsWith('aVZCT1J') || e.startsWith('LzlqLzR')) {
                    base64 = identifyAndDecodeBase64(e);
                } else {
                    base64 = e;
                }
    
                if (base64.startsWith('/9j/')) {
                    base64 = 'data:image/jpeg;base64,' + base64;
                } else if (base64.startsWith('iVBORw0KGgo')) {
                    base64 = 'data:image/png;base64,' + base64;
                } else if (base64.startsWith('R0lGODlh') || base64.startsWith('R0lGODdh')) {
                    base64 = 'image/gif' + base64;
                } else if (base64.startsWith('aVZCT1J')) {
                    base64 = 'data:image/png;base64,' + identifyAndDecodeBase64(base64);
                } else if (base64.startsWith('UklGR')) {
                    base64 = 'image/webp' + base64;
                }
                else {
                    console.error("Formato de imagem não suportado");
                    window.showWait(false);
                    return;
                }
                
                waitForElement('viewImage',()=>{                    
                    document.getElementById('viewImage').setAttribute('src', base64);
                })
                modal.current.Show();
                window.showWait(false);
            }
        }).catch(e=>{
            window.showWait(false);
        });

       
    }    

    const validarCpf = e => { 
        var res = validar.inputCpf(e);
        
        if (res) {            
            let convenio = props.edit.produtoId;
            let v = e.target.value;
            const CONSULTA_URL = `${API_URL}/Cpf/BuscarDadosPorCpf?cpf=${v}&tipo=${convenio}`;

            isProdutoEspecial(convenio) && window.showWait(true);
            getAxios().get(CONSULTA_URL).then(res => {
                if (res != null) {
                    if (res.data == "") {
                        window.showWait(false);
                        resetFormulario(v);
                        isProdutoEspecial(convenio) && Swal.fire("ATENCAO!", "Nao foi encontrado nenhum cadastro para o cpf informado!", "warning");
                        return;
                    }

                    if (res.data.length > 1 && isProdutoEspecial(convenio)) {
                        setOpcaoSelecao(res.data);
                        window.showWait(false);
                        return;
                    } 
  
                    var dadosPreencher = res.data[0];
                    dadosPreencher.estadoCivil = "Solteiro";
                    if(dadosPreencher.cep) 
                        dadosPreencher.cep = validar.mask(dadosPreencher.cep,'99999-999');
                    preencheFormulario(dadosPreencher);
                }
                window.showWait(false);
                return true;
            }).catch(res => {
                window.showWait(false);
                if (res.response) {
                    if (res.response.status === 404) {
                        resetFormulario(v);
                        Swal.fire("ATENCAO!", "No momento este cliente não está Apto para adquirir este produto!", "warning");
                    } else {
                        Swal.fire("Erro!", "Ocorreu um erro ao processar a solicitação!", "error");
                    }
                } else if (res.request) {
                    Swal.fire("Erro!", "Nenhuma resposta recebida do servidor!", "error");
                } else {
                    Swal.fire("Erro!", "Erro ao configurar a solicitação!", "error");
                }

                return false;
            });

        } else if (res == false) {
            window.showWait(false);
            window.modalAlert.Show({ title: "Atencao!", message: "Cpf inválido!", type: "error" });
            return false;
        }

    }
    const showPainelSelecaoOpcaoMatricula = () => {
        preencheFormulario(opcaoSelecao[0]);
        modalSelecaoMatricula.current.Show();
    }

    useEffect(() => {
        if (opcaoSelecao.length > 0)
            showPainelSelecaoOpcaoMatricula();
    }, [opcaoSelecao]);


    const carregarProposta = e => {
        window.showWait(true);
        const form = formRef.current;
        form.reset();

        if (e.isEdit) {
            waitForElement('cpf', el => {
                el.setAttribute('disabled', 'disabled');

                var els = document.querySelectorAll('#bloco_cadastro [name]');
                Array.from(els).map(x => x.setAttribute('disabled', 'disabled'));
            })
        }

        setDados({ id: 0 });

        if (e == undefined || e?.id == undefined) {
            setProduto(props.produto);
            window.showWait(false);
            return;
        }



        getAxios().get(API_URL + "/Proposta/" + e.id).then(res => {
            var data = { ...res.data };
            var prod = props.produtos.filter(x => x.id == data.produtoId);
            if (prod.length == 0) {
                Swal.fire("ATENCAO!", "Existe algum erro nesse cadastro!", "warning");
                props.irPaginaPrincipal();
                window.showWait(false);
                return;
            }

            data.uf_text = data.uf;
            setDados(data);

            var prd = prod[0];
            setProduto(prd);
            props.setProduto(prd);
            preencheFormulario(data);


            if (e.isView) {
                var elemetos = Array.from(formRef.current);
                elemetos.forEach(element => {

                    if (element.id != "btnCancelar" && !element.classList.contains('noLock'))
                        element.setAttribute('disabled', 'disabled');

                    if (element.id == "btnSalvar")
                        element.remove();

                    if (element.id == "btnAprovarAuditoria")
                        element.remove();
                });

                //var els = document.querySelectorAll('.noLock');
                //Array.from(els).map(x => x.setAttribute('disabled', ''));                
            }
            window.showWait(false);
            var el = document.getElementById('cpf');
            el?.focus();

        }).catch(ex => window.showWait(false));
    }


    useEffect(() => {
        setVisualizar(false);
        carregarProposta({ ...props.edit });
        setUsuario(JSON.parse(window.localStorage.getItem("cnf")));
        if (usuario) {
            setUsuarioId(JSON.parse(window.localStorage.getItem("cnf")).usuario.id);
            setVendedorId(JSON.parse(window.localStorage.getItem("cnf")).usuario.RevendedorId);
        }
    }, [props.edit]);

    const selecionaOrgao = valor => {
        setOrgao(valor);
    }

    const cancelar = e => {
        props.irPaginaPrincipal();
    }

    const aprovarAuditoria = () => {
        window.showWait(true);
        var URL = `${API_URL}/Proposta/AtivarDegustacaoAASPA`;
        if (dados.id > 0) {
            const dataAprovacaoAuditoria = {
                usuarioId: JSON.parse(window.localStorage.getItem("cnf")).usuario.id,
                propostaId: dados.id
            };

            getAxios().post(URL, dataAprovacaoAuditoria).then(res => {
                window.showWait(false);
                Swal.fire("Sucesso!", res.data, "success");
                props.irPaginaPrincipal(true);
            }).catch(res => {
                console.log(res);
                window.showWait(false);
                Swal.fire("Erro!", "<ul>" + JSON.stringify(res.response.data).split("\\r\\n").map(x => "<li>" + x + "<li>").join('') + "</ul>");
            });
            window.showWait(true);
        }

    }

    const atualizar = () => {
        carregarProposta({ ...props.edit });
    }

    const salvar = () => {

        ValidateForm(formRef.current).then(ret => {
            if (ret.length > 0) return;

            window.showWait(true);
            var URL = `${API_URL}/Proposta`;

            //var res = FromDataForm(formRef.current, true);
            var res = FromFormReference(formRef.current,true);

            //Parametros de formulario
            if (res.docIdentidade) res.docIdentidade = res.docIdentidade + "";
            if (res.endNumero) res.endNumero = res.endNumero + "";
            if (res.cep) res.cep = res.cep + "";
            if(res.docIdentidadeDataEmissao == "") res.docIdentidadeDataEmissao = null;

            //Parametros de Usuario
            res.VendedorUsuarioId = JSON.parse(window.localStorage.getItem("cnf")).usuario.id;
            // res.VendedorUsuarioId = JSON.parse(window.localStorage.getItem("cnf")).usuario.RevendedorId;
            res.ProdutoId = produto.id;
            res.RevendedorId = JSON.parse(window.localStorage.getItem("cnf")).usuario.revendedorId;
            res.StatusId = StatusId;
            res.CodEmpresa = CodEmpresa;
            res.PropostaAnexos = anexos

            if (isProdutoEspecial(produto?.id ?? 0)) res.tipoPagamentoId = 1; //Coloca como padrao para UNARH e AASPA

            res.dataNascimento = stringToData(res.dataNascimento);
            if (res.docIdentidadeDataEmissao) res.docIdentidadeDataEmissao = stringToData(res.docIdentidadeDataEmissao);

            if (res.sexo == undefined) {
                window.modalAlert.Show({ title: "Erro", message: "Sexo invalido!", type: "error" });
                return;
            }


            //SIAPE
            if (res.matricula) res.matricula = res.matricula + "";
            res.Identificadores = [{ Nome: "Orgao", Valor: orgao + "" }, { Nome: "Instituidor", Valor: res.instituidorMatricula + "" }];

            const config = {
                headers: { Authorization: `Bearer ${usuario.token}`, userId: usuario.usuario.id }
            };

            if (dados.id > 0) {
                res.id = dados.id;
                getAxios().put(URL, res, config).then(res => {
                    window.showWait(false);
                    Swal.fire("Sucesso!", "Proposta atualizada com sucesso!", "success");
                    props.irPaginaPrincipal(true);
                }).catch(res => {
                    console.log(res);
                    window.showWait(false);
                    Swal.fire("Erro!", "<ul>" + JSON.stringify(res.response.data).split("\\r\\n").map(x => "<li>" + x + "<li>").join('') + "</ul>");
                });
            }
            else {
                getAxios().post(URL, res, config).then(res => { 
                    window.showWait(false);
                    Swal.fire("Sucesso!", "Proposta cadastrada com sucesso!", "success");
                    props.irPaginaPrincipal(true);
                }).catch(res => {
                    console.log(res);
                    window.showWait(false);
                    Swal.fire("Erro!", "<ul>" + JSON.stringify(res.response.data).split("\\r\\n").map(x => "<li>" + x + "<li>").join('') + "</ul>");
                });
            }
        });
    }

    const validarData = e => {
        var valid = validar.isValidDate(e.target.value);
        if (!valid) window.modalAlert.Show({ title: "Atencao!", message: "Data de nascimento inválida!", type: "error" });
    }
    const tipoSexo = [{ value: "Masculino", text: "Masculino" }, { value: "Feminino", text: "Feminino" }, { value: "Outro", text: "Outro" }];

    const selecionaMatricula = e => {
        preencheFormulario(e);
        setTimeout(() => {
            waitForElement("nomeCliente", el => {
                el.focus();
            })
        }, 200);
    }

    return <> 
         <MModal size={1.5} ref={modal} title="Seleção de Anexo" closeText="Fechar" >
            <Col className="col-12">
                <img id="viewImage" style={{ width: "100%", height: "100%" }} />
            </Col>
        </MModal>
        <MModal size={2} ref={modalSelecaoMatricula} id="modalSelecaoMatricula" title="Selecione a matrícula desejada:" closeText="Cancelar"  >
            <Row>
                <Col>
                    <strong>Nome: {opcaoSelecao.length > 0 ? opcaoSelecao[0].nomeCliente : ""}</strong>
                </Col>
            </Row>
            <table className="table tableselect  mt-5">
                <thead>
                    <tr>
                        <td>Matrícula</td>
                        {isUnaRh(produto.id) &&
                            <>
                                <td>Instituidor</td>
                                <td colSpan={2} >Órgão</td>
                            </>
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        opcaoSelecao.map(e =>
                        (<tr onClick={() => selecionaMatricula(e)} data-bs-dismiss="modal">
                            <td>{e.matricula}</td>
                            {isUnaRh(produto.id) &&
                                <>
                                    <td>{e.instituidorMatricula}</td>
                                    <td>{e.codigoOrgao}</td>
                                    <td>{e.orgao}</td>
                                </>}
                        </tr>)
                        )
                    }
                </tbody>
            </table>
        </MModal>
        <form ref={formRef} >
            <div id="bloco_cadastro">
                <Row>
                    <Col className='col-3'>
                        {dados.id > 0 &&
                            <div>CÓDIGO DA PROPOSTA: <b>{dados.id}</b></div>
                        }
                    </Col>
                    <Col className='col-9'  >
                        {dados.id > 0 &&
                            <table className='tbStatus'>
                                <tr>
                                    <td colSpan={2} >STATUS DA PROPOSTA:</td>
                                </tr>
                                <tr>
                                    <td><b>{dados.nomeStatus?.toUpperCase()}</b> </td>
                                    <td><div className='bolinha' style={{ backgroundColor: corColunaStatus(dados.statusId) }} ></div> </td>
                                </tr>
                            </table>
                        }
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className='col-8 col-md-2'>
                        <MInput type="mask" name="cpf" validate={e => e.string().required('Campo Cpf obrigatorio!').test('validacpf', 'Cpf invalido!', validar.cpf)} onBlur={e => validarCpf(e)} mask="999.999.999-99" title="Cpf" />
                    </Col>
                    <Col className="col-12 col-md-4">
                        <MInput ref={nomeClienteRef} id="nomeCliente" required type="text" title="Nome" />
                    </Col>
                    <Col className='col-6 col-md-2'>
                        <MInput type="select" id="estadoCivil" required data={tipoEstadoCivil} title="Estado Civil" />
                    </Col>
                    <Col className='col-6 col-md-2'>
                        <MInput type="select" id="sexo" data={tipoSexo} title="Sexo" />
                    </Col>
                    <Col className="col-md-2">
                        <MInput id="dataNascimento" type="mask" required mask="99/99/9999" title="Dt.Nascimento" onBlur={e => validarData(e)} value={dados.dataNascimento || ''} />
                    </Col>
                </Row>
                <Row>
                    {isProdutoEspecial(produto.id) &&
                        <Col className="col-12 col-md-2">
                            <MInput id="matricula" type="text" disabled={ dados.id !== null ? "disabled" : "" } required title="Matrícula" />
                        </Col>
                    }
                    {isUnaRh(produto.id) &&
                        <>
                            <Col className="col-12 col-md-2">
                                <MInput id="instituidorMatricula" type="text" title="Matrícula do Instituidor" />
                            </Col>
                            <Col className='col-12 col-md-8'>
                                <MInput id="orgao"
                                    type="selectSearch"
                                    title="Orgao"
                                    required
                                    placeholder="Digite o nome ou código do órgão..."
                                    url={urlOrgao} params={v => ({ filtro: v })}
                                    onResult={res => res.map(z => ({ value: z.id, text: z.id + " - " + z.nome }))}
                                    onSelect={e => selecionaOrgao(e)}
                                />
                            </Col>
                        </>}
                </Row>
                <hr />
                <strong>IDENTIDADE</strong>
                <Row>
                    <Col className="col-md-3 col-6">
                        <MInput id="docIdentidade" required type="text" title="Nº Identidade" />
                    </Col>
                    <Col className="col-md-3 col-6">
                        <MInput id="docIdentidadeUf" type="text" title="UF Identidade" maxLength="2" />
                    </Col>
                    <Col className="col-md-3 col-6">
                        <MInput id="docIdentidadeOrgEmissor" type="text" title="Órgão Emissor" />
                    </Col>
                    <Col className="col-md-3 col-6">
                        <MInput id="docIdentidadeDataEmissao" type="mask" mask="99/99/9999" title="Data Emissão" value={dados.docIdentidadeDataEmissao || ''} />
                    </Col>

                </Row>
                <hr />
                <strong>DADOS GERAIS</strong>
                <Row>
                    <Col className="col-md-2 col-6">
                        <MInput name="telefonePessoal" required type="mask" mask="(99)99999-9999" title="Telefone Celular" onBlur={e => normalizaTelefone(e)} />
                    </Col>
                    <Col className="col-md-2 col-6">
                        <MInput name="telefoneCorporativo" type="mask" mask="(99)99999-9999" title="Telefone Fixo" onBlur={e => normalizaTelefone(e)} />
                    </Col>
                    <Col className="col-md-8 col-12">
                        <MInput id="nomeMae" required type="text" title="Nome da Mãe" />
                    </Col>
                    <Col className="col-md-6 col-12">
                        <MInput id="emailPessoal" type="email" title="Email Pessoal" />
                    </Col>
                    <Col className="col-md-6 col-12">
                        <MInput id="emailCorporativo" type="email" title="Email Corporativo" />
                    </Col>

                </Row>
                <hr />
                <strong>ENDEREÇO</strong>
                <Row>
                    <Col className='col-6 col-md-2' >
                        <MInput id="cep" required name="cep" type="mask" mask="99999-999" onFocus={e => console.log(e)} title="Cep" onBlur={e => pesquisacepproposta(e.target.value)} />
                    </Col>
                    <Col className='col-12 col-md-6'>
                        <MInput id="logradouro" required type="text" title="Logradouro" />
                    </Col>
                    <Col className='col-4 col-md-1'>
                        <MInput id="endNumero" required type="text" title="Número" />
                    </Col>
                    <Col className='col-8 col-md-3' >
                        <MInput id="complemento" type="text" title="Complemento" />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-6 col-md-6' >
                        <MInput id="bairro" required type="text" title="Bairro" />
                    </Col>
                    <Col className='col-6 col-md-4' >
                        <MInput id="cidade" required name="cidade" type="text" title="Cidade" />
                    </Col>
                    <Col className='col-4 col-md-2' >
                        <MInput id="uf" required data={listaEstados.map(x => ({ value: x, text: x }))} type="selectSearch" title="UF" />
                    </Col>
                </Row>
                {!isProdutoEspecial(produto.id) &&
                    <Row>
                        <Col>
                            <MInput type="select" id="tipoPagamentoId"
                                url={API_URL + "/proposta/ListarTiposPagamentos"}
                                onResult={item => item?.map(x => ({ value: x.id, text: x.nome }))}
                                title="Tipo de Pagamento" />
                        </Col>
                    </Row>
                }
                {JSON.parse(window.localStorage.getItem("cnf")).perfilId > 5 && dados.longitude && dados.latitude &&
                    <>
                        <hr />
                        <strong>GEOLOCALIZAÇÃO</strong>
                        <Row>
                            <Col className="col-12 col-md-4">
                                <MInput data={dados.latitude} id="latitude" disabled type="text" title="Latitude" />
                            </Col>
                            <Col className="col-12 col-md-4">
                                <MInput data={dados.longitude} id="longitude" disabled type="text" title="Longitude" />
                            </Col>
                            <Col className="col-12 col-md-4">
                                <br /><br /> <br />
                                <a
                                    href={gerarUrlGeolocalizacao(dados.latitude, dados.longitude)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="fas fa-map-marker-alt"
                                >
                                    Abrir no Mapa
                                </a>
                            </Col>
                        </Row>
                    </>
                }
            </div>
            <hr />

            <AnexosProposta setarAnexos={setarAnexos} recarregarProposta={recarregarProposta} anexos={anexos} visualizarAnexo={visualizarAnexo} />

            <hr />
            <HistoricoProposta dados={dados.propostaHistoricos} visualizarAnexo={visualizarAnexo} />

            <div style={{ height: 500 }}>
            </div>
            <Row>
                <div style={{ height: "800px;" }}>
                    <br></br>
                </div>
            </Row>
            <div className='footer'>
                <table>
                    <tr>
                        <td>
                            {(() => {
                                const cnf = JSON.parse(window.localStorage.getItem("cnf"));
                                if (cnf && (cnf.perfilId > 5) && dados.statusId > 6 && dados.statusId <= 10) {
                                    return (
                                        <Button className='btn-sm' title="Aprovar Auditoria" id="btnAprovarAuditoria" style={{ backgroundColor: 'green', color: 'white' }} onClick={() => aprovarAuditoria()}>
                                            <i className="fa fa-edit"></i>
                                            Aprovar
                                        </Button>
                                    );
                                }
                                return null;
                            })()}
                        </td>
                        <td>

                            <Button className='btn-info btn-sm' id="btnSalvar" onClick={() => atualizar()} >
                                <i className="fa fa-recycle"></i>
                                Atualizar
                            </Button>
                        </td>
                        <td>

                            <Button className='btn-sm' id="btnSalvar" onClick={() => salvar()}>
                                <i className="fa fa-save"></i>
                                Salvar
                            </Button>

                        </td>
                        <td>
                            <Button id="btnCancelar" onClick={e => cancelar()} className="btn-secondary btn-sm">
                                <i className="fa fa-sign-out" aria-hidden="true"></i>
                                Fechar
                            </Button>
                        </td>
                    </tr>
                </table>
            </div>

        </form >       
    </>
}
