/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { API_URL } from '../../../components/MConfig'
import { getAxios } from '../../../components/net'
import { MInput } from '../../../components/MInput/MInput'
import { Col, Row } from 'react-bootstrap-v5'
import { MInputPassword } from '../../../components/MInput/MInputPassword'

const loginSchema = Yup.object().shape({
  login: Yup.string()
    .min(3, 'Minimo 3 simbolos')
    .max(50, 'Maximo de 50 simbolos')
    .required('Login é obrigatório'),
  password: Yup.string()
    .min(1, 'Minimo de 1 caractere.')
    .max(50, 'Maximo 50 caracteres')
    .required('Senha é obrigatória'),
})

const initialValues = {
  login: '',
  password: '',
  captcha: '',
  tokenImage: '',
}

export function Login() {

  const [loading, setLoading] = useState(false)
  const [versao, setVersao] = useState('...')
  const [captcha, setCaptcha] = useState('')



  const [meuip, setMeuIp] = useState("");


  useEffect(() => {
    carregarCaptcha();    
    var caminhoLogo = toAbsoluteUrl('/media/logos/fundo.png');
    document.querySelectorAll('body')[0].style.backgroundImage = caminhoLogo;
  }, []);

  const carregarCaptcha = () =>{
    const CONSULTA_URL = `${API_URL}/login/captcha`;
    getAxios().get(CONSULTA_URL).then(x => {      
      var b = 'data:image/png;base64,' + x.data.image;
      document.getElementById('captchaimage')?.setAttribute('src', b);
      setCaptcha(x.data.text);
    });
  }

  const limparCaptcha = () =>{
    carregarCaptcha();
    document.getElementById('captcha')?.setAttribute('value','');
  }

  const buscaIp = () => {
    const CONSULTA_URL = `${API_URL}/login/meuip`;
    getAxios().get(CONSULTA_URL).then(res => {
      setMeuIp(res.data);
    });
  }

  const buscaVersao = () => {
    const CONSULTA_URL = `${API_URL}/login/versao`;
    getAxios().get(CONSULTA_URL).then(res => {
      setVersao(res.data);
    });
  }

  useEffect(() => {
    buscaIp();
    buscaVersao();
  }, [])

  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        const LOGIN_URL = `${API_URL}/login/validar`;        
        var tokenImage =  document.getElementById('tokenImage')?.getAttribute('value');
        getAxios().post(LOGIN_URL, { login: values.login, senha: values.password, captcha: values.captcha, token : tokenImage })
            .then((info: any) => {  
                var data = info.data;
                if (data?.sucesso) {
                  var dt = data.data;
                  dispatch(auth.actions.login(dt));
                                  
                  var cnf = { ...data, nome : data.usuario.nome }

                  document.body.style.backgroundImage = "";

                  //Config
                  window.localStorage.setItem("cnf", JSON.stringify(cnf));                    
                    dispatch(auth.actions.login(cnf))
                    setLoading(false)
                } else {
                  setLoading(false)
                  setSubmitting(false)
                  if (data.message != null) {
                    setStatus(data.message)
                    if(data.message.indexOf('Dados digitados para imagem') > -1)
                      limparCaptcha();
                  }
                  else if (data.errors != null) {
                    setStatus(data.errors)
                  }
                  else if (data.block) {
                    setStatus('Usuário bloqueado.')
                  }
                  else {
                    setStatus('Informações de login são invalidas.')
                  }
                }
          })
          .catch((ex) => {            
            setLoading(false)
            setSubmitting(false)
            setStatus(ex?.response?.data ?? 'Informações de login são invalidas. ')
          })
      }, 1000)
    },
  })

  return (
    <>
    <div className='bodyDiv'></div>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
        style={{zoom: 0.9}}
      >
        <small style={{ position: 'fixed', left: 0, top: 0, padding: 10 }} >Versao {versao}</small>
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Efetuar Login</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            {meuip && <span>Seu ip é : {meuip}</span>}
            {/*
          Novo aqui?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Criar nova conta
          </Link>
          */}
          </div>
        </div>
        {/* begin::Heading */}

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Login</label>
          <input
            placeholder='Login'
            {...formik.getFieldProps('login')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.login && formik.errors.login },
              {
                'is-valid': formik.touched.login && !formik.errors.login,
              }
            )}
            type='text'
            name='login'
            autoComplete='off'
          />
          {formik.touched.login && formik.errors.login && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.login}</span>
            </div>
          )}
        </div>




        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Senha</label>
              {/* end::Label */}
              {/* begin::Link */}
              <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{ marginLeft: '5px' }}
              >
                Esqueceu a senha ?
              </Link>
              {/* end::Link */}
            </div>
          </div>
          <MInputPassword
            autoComplete='off'
            {...formik.getFieldProps('password')} />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>


        {/* end::Form group */}

        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              <Row>
                <div className='col-md'>
                  <input type='image'  id="captchaimage" className='h-85px' src='/media/imgs/aguarde.png' />
                </div>
                <div className='col-md'>
                <small>Digite a imagem abaixo:</small> <br/>
                <input type='hidden' id="tokenImage" value={captcha} />
                <a href='#' onClick={()=> carregarCaptcha()} >Recarregar imagem</a>
                  <input
                    id="captcha"
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('captcha')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.captcha && formik.errors.captcha,
                      },
                      {
                        'is-valid': formik.touched.captcha && !formik.errors.captcha,
                      }
                    )}
                  />
                </div>
              </Row> 
            </div>
          </div>

          {formik.touched.captcha && formik.errors.captcha && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.captcha}</span>
              </div>
            </div>
          )}
        </div>

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continuar</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Aguarde...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>

        </div>
        {/* end::Action */}
      </form>

    </>
  )
}
