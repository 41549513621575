import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import { AlterarSenhaPage } from '../modules/portal/AlterarSenha/AlterarSenhaPage'
import { ConfiguracoesPage } from '../modules/portal/Configuracoes/ConfiguracoesPage'
import { Propostas } from '../modules/portal/Proposta/Propostas'
import { Empresa } from '../modules/portal/Empresa/Empresa'
import { Usuario } from '../modules/portal/Usuario/Usuario'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />       
        <Route path='/alterarsenha' component={AlterarSenhaPage} />        
        <Route path='/configuracoes' component={ConfiguracoesPage} />        
        <Route path='/empresa' component={Empresa} /> 
        <Route path='/propostas' component={Propostas} /> 
        <Route path='/usuarios' component={Usuario} /> 
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
