import { MModal } from "../../../components/MModal/MModal"
import { useEffect, useRef, useState } from "react"
import { toAbsoluteUrl } from "../../../../_metronic/helpers"
import { fire } from "../../../../util";
import { Col, Row } from "react-bootstrap-v5";

export const ModalSelecaoProduto = props => {
    var modalSelecao = useRef();

    var modal = <>
        <MModal size={2} id="modalSelecao" ref={modalSelecao} title="Seleção de Produto" closeText="Cancelar"  >
            Selecione a opcao de produto abaixo:

            <Row className="mt-5"  >
                {
                    props.produtos?.map(e =>
                    (<Col className="col-6 col-md-4 text-center mt-5 selectItem" onClick={() => props.setProduto(e)} data-bs-dismiss="modal" key={e.id} >
                        <img alt='Pic' style={{ width: 80, height: 70 }} src={toAbsoluteUrl(`/media/convenios/convenio${e.id}.png`)} /> <br />
                        {e.nome}
                    </Col>)
                    )
                }
            </Row>
        </MModal>
    </>


    useEffect(() => {
        fire.register('selecionar_produto', () => {
            modalSelecao.current?.Show();
        });
    }, []);

    return modal;
}