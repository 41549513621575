import { React } from 'react';
import { API_URL } from "./app/components/MConfig";
import { getAxios } from "./app/components/net";
import { DadosExternos } from "./app/dados";
import { eventNames } from 'process';
import Swal from 'sweetalert2';
import { tipoPerfil, tipoPermissao } from './app/modules/portal/tipos/tipos';
const codigoAdministrador = 64;
export const guid = (beforeText = null) => {
    //var today = new Date(); 
    //return (beforeText ?? "") + today.getMinutes() + "-" + today.getSeconds();
    return (beforeText ?? "") + Math.floor((Math.random()) * 0x10000)
        .toString(16);
}

export const configuracao = (descricao, funcSet) => {
    const CONSULTA_URL = `${API_URL}/datai/configuracao/${descricao}`;

    let gname = guid('vlr');

    getAxios().get(CONSULTA_URL).then(x => {
        funcSet(x.data);
    });

    return <span id={gname}></span>
}

export const corColunaStatus = status =>{
    if(status == 5) return "#b8b804"; //Selfie fragil
    if(status > 6 && status < 11) return "pink"; //Aguard.Auditoria
    if(status == 11) return "blue"; //Aguard.Averbacao
    if(status == 12) return "green"; //Ativo
    if(status == 13) return "red"; //Cancelado
    return "dimgrey";
}

export const modalAlert = window.modalAlert;

export const getIdUsuario = () => {
    return getConfig().id;
}

export const getRole = id => {
    var cnf = getConfig();

    //var permissao = cnf.permissoes.indexOf(id) >= 0 || cnf.permissoes.indexOf(codigoAdministrador) >= 0
    //return permissao;

    return [];
};
export const getRoles = (ids) => {

    var cnf = getConfig();

    var permissoes = false;
    ids.forEach(id => {
        if (cnf.permissoes.indexOf(id) >= 0) permissoes = true;
    });

    var permissao = permissoes || cnf.permissoes.indexOf(codigoAdministrador) >= 0

    return permissao;
};

export const getConfig = () => {
    var ww = window;
    if (!(ww.cnf == null || ww.cnf == undefined)) return ww.cnf;

    var cnfstr = window.localStorage.getItem("cnf") ?? "{\"permissoes\":[]}";
    var cnf = JSON.parse(cnfstr);
    ww.cnf = cnf;

    return cnf;
}

export const validarAutenticacao = e => {
    if (e?.response?.status == 401) window.location.href = '/logout';
}

export function isNumeric(n) {
    n = n?.replace(',', '.') ?? n;

    return !isNaN(parseFloat(n)) && isFinite(n);
}
const subElement = (object, element) => {
    if (element.name.indexOf('.') > -1) {
        var lstName = element.name.split('.');
        var n = [];

        for (var i = 0; i < lstName.length - 1; i++) {
            var xItem = lstName[i];
            n.push(xItem);
            var nEl = n.join(".");
            if (!eval("object." + nEl))
                eval("object." + nEl + " = {}");
        }
    }
}


export const isNumer = value => Number.isInteger(parseInt(value));

export const copyToClipboard = textToCopy => {

    var obj = {};
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(textToCopy).then(function () {
            obj = { status: true, msg: 'Texto copiado para a área de transferência!' };
        }).catch(function (error) {
            obj = { status: false, msg: 'Falha ao copiar texto', error };
        });
    } else {

        var textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        textArea.style.position = 'fixed';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            var successful = document.execCommand('copy');
            if (successful) {
                obj = { status: true, msg: 'Texto copiado para a área de transferência!' };
            } else {
                obj = { status: false, msg: 'Falha ao copiar texto' };
            }
        } catch (err) {
            obj = { status: false, msg: 'Erro ao tentar copiar texto', error: err };
        }
        document.body.removeChild(textArea);
        return obj;
    }
}

export const FromFormReference = (item,evenDisabled) => {    
    var items = Array.from(item);
    var result = {};
    /*
    if(evenDisabled){
        var disabledElements = item.querySelectorAll(':disabled');        
        disabledElements.forEach(function(element) {
            item.append(element.name, element.value);
        });
        items = Array.from(item);
    }
    */


    for (var i in items) {
        var element = items[i];
        if (element.name) {
            var name = element.name.replace('[]', '');
            
            var dataType = element.attributes['dataType']?.value;
                        
            var value = (dataType == "int" ? 
                            (isNumeric(element?.value ?? "") ?
                                 parseInt(element.value) : null) : element?.value + "");
            if (name.indexOf('.') > -1)
                subElement(result, element);

            if (element.name.indexOf('[]') > -1) {
                if (!eval("result." + name))
                    eval("result." + name + " = [];");

                if (element.checked)
                    eval("result." + name + ".push(value)");
                else if (element.type != 'checkbox')
                    eval("result." + name + ".push(value)");
            }
            else { 
                
                if(dataType){
                    eval("result." + name + " = value");                
                } else {
                    eval("result." + name + " = value + ''");
                }

                if (element.checked) 
                    eval("result." + name + " = value");
            }
        }
    }

    return result;
}
export const ValidateForm = form => {

    var vals = [];
    var els = [];

    if (Array.isArray(form))
        els = form;
    else
        els = Array.from(form.elements);

    els.forEach(element => {
        if (element.validate) {
            vals.push(element.validate());
        }
    });

    return new Promise((resolve, reject) => {

        Promise.all(vals).then(res => {

            var r = '<ul>';
            var v = res.filter(x => x != undefined);
            v.map(x => r += `<li>${x}</li>`);
            r += "</ul>";

            if (v.length > 0) {
                Swal.fire("Erros!", r, "warning");
            } else {
                resolve?.call(null, v);
            }
        });
    });
}
export const FromDataForm = (form,evenDisabled) => {

    var item = new FormData(form);

    let obj = {};

    if(evenDisabled){
        var disabledElements = form.querySelectorAll(':disabled');        
        disabledElements.forEach(function(element) {
            item.append(element.name, element.value);
        });
    }

    item.forEach(function (value, key) {
        
        if (key.indexOf('.') > -1) {
            var pre = key.substring(0, key.indexOf('.'));
            if (obj[pre] == undefined) obj[pre] = {};
        }

        if (isNumeric(value)) {
            value = parseFloat(value.replace(',', '.'));
        }

        if (!(value === undefined || value === null || value === "")) {
            if (key.indexOf('[]') > -1) {
                key = key.replace('[]', '')
                var ps = obj[key];
                if ((ps == undefined || ps == null)) ps = [];

                eval('obj.' + key + ' = ps;');
                ps.push(value);
            } else {
                var z = value == "on" ? 1 : value;
                eval('obj.' + key + ' = z;');
            }
        }
    });

    return obj;
}

export const stringToData = dataString => {
    if(!dataString) return null;
    var partesData = dataString.split('/');

    var ano = parseInt(partesData[2]);
    var mes = parseInt(partesData[1]) - 1;
    var dia = parseInt(partesData[0]);

    return new Date(ano, mes, dia);
}
export const fromIntToData = (data) => {
    if (data == null) return "";
    data = "" + data;
    return data.substring(6, 8) + "/" + data.substring(4, 6) + '/' + data.substring(0, 4)
}
export const toDateTime = (data) => {
    var res = new Date(data).toLocaleString('pt-BR');
    return res;
}
export const toData = (data, reverse = false) => {
    if(data == null || data == undefined) return null;
    if (typeof (data) == 'string') {
        var pos = data.indexOf('T');

        if (pos > -1) {
            var data1 = data.substring(0, pos);
            var dtSplit = data1.split('-');

            if (dtSplit[0].length == 4) {
                data = dtSplit[2] + "/" + dtSplit[1] + "/" + dtSplit[0];
            }
            return data;
        } else {

            if (data.length == 8) {
                var dt = "";
                if (reverse)
                    dt = data.substring(0, 4) + "/" + data.substring(4, 6) + '/' + data.substring(6, 8)
                else
                    dt = data.substring(6, 8) + "/" + data.substring(4, 6) + '/' + data.substring(0, 4)
                return dt;
            }

            if (data.length == 6) {
                var dt = data.substring(4, 6) + '/' + data.substring(0, 4)
                return dt;
            }

        }
    }

    var result = new Date(data).toLocaleDateString('pt-BR');
    return result;
}
export const buscaBanco = codigo => {
    var code = parseInt(codigo ?? 0)
    var result = DadosExternos.listaBancos.filter(x => parseInt(x.value) == code)[0];
    if (result != null) return result.value + " " + result.label;
    return "";
}

export const ativoInativo = valor => {
    return parseInt(valor) == 1 ? "Ativo" : "Inativo"
}

export const toMoeda = valor => {
    if (valor == null) valor = 0;
    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

export const dateFormat = (inputDate) => {
    if (inputDate != null) inputDate = new Date(inputDate);
    if (inputDate == null || inputDate == undefined) inputDate = new Date();
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();

    date = date
        .toString()
        .padStart(2, '0');

    month = month
        .toString()
        .padStart(2, '0');

    return `${date}/${month}/${year} - ${inputDate.getHours()}:${inputDate.getMinutes()}`;
}

export const nascimentoFormat = q => {
    if (q == null || q == undefined) q = 0;
    if (q == 0) return "";
    q = q.toString();
    var saida = q.substr(6, 2) + "/" + q.substr(4, 2) + "/" + q.substr(0, 4);
    return saida;
}


export const objectToQueryString = (obj, prefix) => {
    const str = [];
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const k = prefix ? `${prefix}.${key}` : key;
            const v = obj[key];
            str.push((v !== null && typeof v === 'object') ?
                objectToQueryString(v, k) :
                encodeURIComponent(k) + '=' + encodeURIComponent(v));
        }
    }
    return str.join('&');
}

export const fromObjectToArrayForm = (obj, prefix, lst) => {
    var str = lst ?? [];
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const k = prefix ? `${prefix}.${key}` : key;
            const v = obj[key];
            if ((v !== null && typeof v === 'object'))
                lst = fromObjectToArrayForm(v, k, str, false);
            else
                str.push({ name: k, value: v });
        }
    }
    return str;
}

export const errorAxios = e => {
    console.log({ erro: e });
    if (e?.response?.status == 404)
        //Swal.fire("Nao encontrado!", JSON.stringify(e.response.data), "warning")
        Swal.fire("Nao encontrado!", "Sem dados a serem exibidos!");
    else
        Swal.fire("Erro!", "Ocorreu um erro!");
    //Swal.fire("Erro!", JSON.stringify(e), "error")
}

export const toFormValues = (formRef, formData) => {
    var items = Array.from(formRef?.current?.elements);
    Array.from(formRef.current.querySelectorAll("[readreact]")).map(x => items.push(x));
    items = items.map(x => ({ name: (x.name ?? x.id).toUpperCase(), item: x }));

    //readReact

    if (formData != null) {

        var mapa = fromObjectToArrayForm(formData).map(x => ({ name: x.name.toUpperCase(), value: x.value }));
        var promises = [];
        mapa.forEach(n => {


            promises.push(new Promise((resolve, reject) => {
                var elementArray = items.filter(x => x.name == n.name)[0];
                if (elementArray != undefined) {

                    var value = n.value;
                    var element = elementArray.item;
                    if (value == undefined || value == null) value = "";
                    if (element != null) {

                        var hasSet = () => {
                            if (element.type == 'checkbox')
                                element.checked = value;
                            if (element.type == 'select' || element.type == "select-one") {
                                selectItemOnInputComponent(element, value);
                            }
                            else {
                                if (element.reactSet) {
                                    element.reactSet(value);
                                }
                                else
                                    element.setAttribute("value", value);
                            }

                        }

                        if (element.onSelect)
                            element.onSelect(value);

                        if (!element.hasSet) {
                            element.hasSet = hasSet;
                            hasSet();
                        } else {
                            element.value = n?.value;
                        }

                    }
                }
            }));
            Promise.all(promises);
        });
    }
}

export const selectItemOnInputComponent = (element, value) => {
    var itemSel = Array.from(element.options).map((z, a) => ({ value: z.value, index: a }))?.filter(q => q.value == value)[0]?.index ?? 0;
    element.selectedIndex = itemSel;
}

//Wait Element and callback function
export const waitForElement = (name, func, tries = 30) => {
    var ntries = 0;
    var ntime = setInterval(() => {
        ntries += 1;
        var el = document.getElementById(name);
        if (el) {
            func(el);
            clearInterval(ntime);
        }
        if (ntries >= tries) clearInterval(ntime);
    });
}


export const setAsStringProperties = (obj, objParams) => {
    var els = fromObjectToArrayForm(objParams);
}

export const setStringValues = (obj, objParams) => {
    objParams.forEach(name => {
        var i = eval('obj.' + name);
        if (i) eval('obj.' + name + " = '" + i + "'");
    });
    return obj;
}


export const getPermissao = (permissao, perfil) => {
    var cnf = getConfig();
    var temPermissao = cnf.permissoes.filter(x => x == permissao || x == tipoPermissao.admin);
    var resultado = temPermissao.length > 0;

    if (perfil) {
        if (permissao == null)
            resultado = (cnf.perfilNome == perfil);
        else
            resultado = ((cnf.perfilNome == perfil) && (resultado)) || resultado ;
    };

    return resultado;
}

export const password_isStrongPassword = (password, minLength = 12) => {
    // Define the criteria for a strong password
    const upperCasePattern = /[A-Z]/;
    const lowerCasePattern = /[a-z]/;
    const digitPattern = /[0-9]/;
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;
    // Check if the password meets all the criteria
    if (password.length < minLength) {
        return false;
    }
    if (!upperCasePattern.test(password)) {
        return false;
    }
    if (!lowerCasePattern.test(password)) {
        return false;
    }
    if (!digitPattern.test(password)) {
        return false;
    }
    if (!specialCharPattern.test(password)) {
        return false;
    }

    return true;
}

export const password_generateStrong = (length = 12) => {
    const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const specialCharacters = '!@#$%^&*()_+[]{}|;:,.<>?';

    const allCharacters = upperCase + lowerCase + numbers + specialCharacters;

    let password = '';

    password += upperCase[Math.floor(Math.random() * upperCase.length)];
    password += lowerCase[Math.floor(Math.random() * lowerCase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

    for (let i = 4; i < length; i++) {
        password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }

    password = password.split('').sort(() => Math.random() - 0.5).join('');

    return password;
}


export const Mlistar = (url, paginador, pagina, ordem, funcResult) => {

    const config = {
        headers: { Authorization: `Bearer ${JSON.parse(window.localStorage.getItem("cnf")).usuario.token}`, userId: JSON.parse(window.localStorage.getItem("cnf")).usuario.id }
    };

    var paginacao = paginador.current.setOrder(ordem, pagina);
    var URL = `${API_URL}${url}?` + objectToQueryString(paginacao);
    window.showWait(true);
    getAxios().get(URL, config).then(res => {
        if (res.data) {
            funcResult(res.data);
            window.showWait(false);
        } else {
            Swal.fire("Erro!", res.data.message, "error");
            window.showWait(false);
        }
    }).catch(res => {
        funcResult([]);
        errorAxios(res)
        window.showWait(false);
    });

}

export const fire = (function () {

    var funcList = [];
    var callbackRun = [];


    var varrer = () => {
        for (var i = 0; i < callbackRun.length; i++) {
            var cb = callbackRun[i];
            var fc = funcList.filter(x => x.name == cb.eventName);
            if (fc.length > 0) {
                fc[0].func(cb.params);
                callbackRun.splice(0, 1);
            }
        }
    }

    var obj = {
        call: (eventName, params) => {
            callbackRun.push({ eventName, params });
            varrer();
        },
        register: (eventName, functionAdd) => {
            funcList = funcList.filter(x => x.name != eventName);
            funcList.push({ name: eventName, func: functionAdd })
            varrer();
        }
    };


    /*
    setInterval(() => {
        varrer();
    }, 100);*/

    return obj;
}());