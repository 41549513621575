import { useEffect, useState } from "react"
import { ListaPropostas } from "./ListaPropostas"
import { PropostaCadastro } from "./CadastroProposta"
import { MCard, MCardHead } from "../../../components/MCard/MCard"
import { FiltroPropostas } from "./FiltroPropostas"
import { fire, getConfig, getPermissao } from "../../../../util"
import { getAxios } from "../../../components/net"
import { API_URL } from "../../../components/MConfig"
import { ModalSelecaoProduto } from "./ModalSelecaoProduto"
import { toAbsoluteUrl } from "../../../../_metronic/helpers"
import { tipoPermissao } from "../tipos/tipos"

export const Propostas = props => {

    const [edit, setEdit] = useState(null);
    const [produto, setProduto] = useState({});
    const [produtos, setProdutos] = useState([]);
    const [status, setStatus] = useState([]);
    const [recarregar, setRecarregar] = useState(false);

    const novo = () => {
        fire.call('selecionar_produto');
    }

    const irPaginaPrincipal = (v = false) => {
        setEdit(null);
        setRecarregar(v)
    }

    useEffect(() => {
        
        var idRevenda = getConfig().usuario.revendedor.id;
        getAxios().get(API_URL + "/produto/ListarProdutosPorRevendaId/" + idRevenda).then(res=>{
            if (res.data) {
                setProdutos(res.data.map(x=> ({ nome : x.nomeProduto ,id:x.produtoId})));
            }
        });

        getAxios().get(API_URL + "/proposta/ListarStatus").then(res => {
            if (res.data) {
                setStatus(res.data);
            }
        });

        fire.register('visualizar_proposta', e => {
            var item = { ...e };
            item.isView = true;
            editarCadastro(item);
        });

    }, []);

    const editarCadastro = e => {
        e.isEdit = true;
        setProduto(e.produtoId);
        setEdit(e);
    }

    const setarProduto = e => {
        setProduto(e);
        setEdit({ produtoId: e.id });
    }
 
    return <>
        {getPermissao(tipoPermissao.criar_proposta) &&
            <MCard>
                <MCardHead title={<i className="fa fa-cubes"> Cadastro de Propostas {edit != null && <span>- {produto?.nome}</span>} </i>}>
                    {edit && <img alt='Pic' style={{ width: 100, height: 70 }} src={toAbsoluteUrl(`/media/convenios/convenio${produto?.id}.png`)} />}
                </MCardHead>
                <ModalSelecaoProduto produtos={produtos} setProduto={setarProduto} />

                <div style={{ display: (edit == null ?"block" :  "none" ) }}>
                    <FiltroPropostas novo={novo} produtos={produtos} status={status} />
                    <ListaPropostas recarregar={recarregar} produtos={produtos} status={status} editarCadastro={editarCadastro} />
                </div>

                {edit != null && //Novo e Edicao
                    <>
                        <PropostaCadastro edit={edit}
                            produtos={produtos}
                            produto={produto}
                            irPaginaPrincipal={irPaginaPrincipal}
                            setProduto={setProduto}
                        />
                    </>
                }
            </MCard>}
    </>

}