import { React, useEffect, useRef, useState } from 'react';
import { FromDataForm, FromFormReference, ValidateForm, fire, getRole, modalAlert, selectItemOnInputComponent, setStringValues, toFormValues, waitForElement } from '../../../../util';
import { MInput } from '../../../components/MInput/MInput';
import { Button, Col, Dropdown, Row } from 'react-bootstrap-v5';
import { validar } from '../../services/Validacoes';
import { pesquisacep } from '../../services/ViaCep';
import { API_URL } from '../../../components/MConfig';
import { getAxios } from '../../../components/net';
import Swal from 'sweetalert2';
import { tipoEmpresas } from '../tipos/tiposJs';
export const CadastroEmpresa = props => {

    var formRef = useRef();
    const [id, setId] = useState(0);
    const [usaInstricao, setUsaInstricao] = useState(true);
    const [tipoConta, setTipoConta] = useState([]);
    const [tipoCadastro, setTipoCadastro] = useState("");
    const [pixFilter, setPixFilter] = useState([]);
    const [ocultos, setOcultos] = useState([]);
    const [mostraOperacao, setMostraOperacao] = useState(false);
    const [produtosComissoes, setProdutosComissoes] = useState([]);

    const tipoPessoa = [{ value: "PJ", text: "Juridica" }, { value: "PF", text: "Fisica" }];

    const tipoContaList = [
        { value: 1, text: "Corrente" },
        { value: 2, text: "Poupanca" },
        { value: 3, text: "Pix - Email" },
        { value: 4, text: "Pix - Telefone" },
        { value: 5, text: "Pix - Chave Aleatoria" },
        { value: 6, text: "Pix - Cpf" },
        { value: 7, text: "Pix - Cnpj" }];



    const modificarTipoPessoa = e => {
        var v = e == "PJ";
        window.usaInstricao = v;
        setUsaInstricao(v);
    }

    const selecionaBanco = e => {
        setMostraOperacao(e.codigo == 104);
    }

    const cancelar = e => {
        props.irPaginaPrincipal();
    }

    const carregarProdutos = e =>{
        getAxios().get(API_URL + "/produto/ListarProdutosPorRevendaId/" + e.value).then(res=>{
            setProdutosComissoes(res.data);
        })        
    }


    const validarCpfCnpj = e => {
        var res = false;
        if (usaInstricao)
            res = validar.cnpj(e);
        else
            res = validar.cpf(e);

        if (!res) {
            var msg = (usaInstricao ? "CNPJ" : "CPF") + ' inválido!';
            window.modalAlert.Show({ title: "Atencao!", message: msg, type: "error" });
            return false;
        }
        return true;
    }

    const setConta = e => {
        e = parseInt(e);
        setTipoConta(e);

        switch (e) {
            case 4:
                setPixFilter('(99)99999-9999');
                break;
            case 6:
                setPixFilter('999.999.999-99');
                break;
            case 7:
                setPixFilter("99.999.999/9999-9");
                break;
            default:
                setPixFilter('');
        }

    }

    const normalizaTelefone = e => {
        var tam = validar.clearMask(e.target.value).length;
        e.target.value = validar.mask(e.target.value, (tam == 10 ? "(99)9999-9999" : "(99)99999-9999"));
    }

    const oculta = (e, idx) => {

        idx = parseInt(idx);
        let c = [...ocultos];

        if (c.indexOf(idx) == -1 && e) {
            c.push(idx);
        } else {
            c = c.filter(x => x != idx);
        }

        setOcultos(c);

    }


    const salvar = () => {

        
        var res = FromFormReference(formRef.current); // FromDataForm(new FormData(formRef.current));
        res.CpfCnpj = usaInstricao ? res.Cnpj : res.Cpf;

        ValidateForm(formRef.current).then(ret => {

            if (ret.length > 0) return;
            window.showWait(true);            
            res.produtos = [];
            for (var i = 0; i < res?.produto?.length; i++)
                res.produtos.push({ produtoId: res.produto[i], comissionamentoId: res.tabela[i] })

            if (!res.TipoPessoa) res.tipoPessoa = "PF";

            delete res.tabela;
            delete res.produto;

            //Envio
            if (id != 0) {
                res.id = id;
                getAxios().put(API_URL + "/revendedor", res).then(ret => {
                    Swal.fire("Sucesso!", "Cadastro atualizado com sucesso!", "success");
                    props.irPaginaPrincipal();
                    window.showWait(false);
                }).catch(ex => {
                    console.log(ex.response.data.errors);
                    Swal.fire("Ocorreu um erro!", ex.response.data, "error");
                    window.showWait(false);
                });
            } else {
                getAxios().post(API_URL + "/revendedor", res).then(ret => {
                    Swal.fire("Sucesso!", "Cadastro realizado com sucesso!", "success");
                    props.irPaginaPrincipal();
                    window.showWait(false);
                }).catch(ex => {
                    console.log(ex.response.data.errors);
                    Swal.fire("Ocorreu um erro!", ex.response.data, "error");
                    window.showWait(false);
                });
            }


        });
    }

    const modificarTipoCadastro = e => {
        carregarProdutos({value: 0});
        setTipoCadastro(e);
        if (e == "Autonomo") setUsaInstricao(false);
    }

    useEffect(() => {
       

    }, [])

    useEffect(() => {
        let vr = formRef.current;
        fire.register('editar_empresa', e => {
            carregarEmpresa(e, vr);
        });

    }, [formRef.current])

    const carregarEmpresa = (e, vr) => {

        const form = vr; //formRef.current;
        form?.reset();

        setId(0);

        if (e == undefined) {
            return;
        }

        getAxios().get(API_URL + "/revendedor/" + e.id).then(res => {
            var data = res.data;
            preencheFormulario(data, form);
            setId(data.id);
            //var el = document.getElementById('CpfCnpj');
            //el?.focus();
        });
    }


    const preencheFormulario = (data, form) => {
        
        data = { ...data };
        data.cpf = data.cpfCnpj;
        data.cnpj = data.cpfCnpj;
        toFormValues({ current: form }, data);


        var funcPreencheCpfCnpj = el => {
            var v = data.cpfCnpj;
            if (el.id == "Cpf") v = validar.mask(v, '999.999.999-99');
            if (el.id == "Cnpj") v = validar.mask(v, "99.999.999/9999-99");
            el.value = v;
            el.reactSet && el.reactSet(v);
        };

        waitForElement('Cpf', funcPreencheCpfCnpj);
        waitForElement('Cnpf', funcPreencheCpfCnpj);

        data?.produtos?.forEach(x => {

            waitForElement('produto_' + x.produtoId, el => {
                el.checked = true;
                el.onSelect && el.onSelect({ target: el });

                waitForElement('comissionamento_' + x.produtoId, e => {
                    selectItemOnInputComponent(e, x.comissionamentoId);
                });
            });

        });
    }

    return <>
        <form ref={formRef}>
            <h2>Cadastro de Empresa</h2>
            <Row>
                <Col className='col-6 col-md-2'>
                    <MInput type="select" id="TipoCadastro" onSelect={e => modificarTipoCadastro(e)} data={tipoEmpresas} title="Tipo Cadastro" />
                </Col>
                {tipoCadastro != "Autonomo" &&
                    <>
                        <Col className='col-6 col-md-2'>
                            <MInput type="select" id="TipoPessoa" onSelect={e => modificarTipoPessoa(e)} data={tipoPessoa} title="Tipo Pessoa" />
                        </Col>
                    </>
                }

                <Col className={'col-8 col-md-' + (usaInstricao ? "3" : "2")}>
                    {
                        usaInstricao && <MInput type="mask"
                            validate={e => e.string().required(`Campo Cpf obrigatorio`).test('valida cnpj', 'Cnpj invalido', validarCpfCnpj)}
                            name="Cnpj"
                            mask={"99.999.999/9999-99"}
                            title="CNPJ" />
                    }
                    {!usaInstricao && <MInput type="mask"
                        validate={e => e.string().required(`Campo Cpf obrigatorio`).test('valida cpf', 'Cpf invalido', validarCpfCnpj)}
                        name="Cpf"
                        mask={"999.999.999-99"}
                        title="CPF" />
                    }

                </Col>
                {
                    usaInstricao &&
                    <>
                        <Col className='col-12 col-md-2' >
                            <MInput id="InscricaoEstadual" type="text" title="Inscrição Estadual" />
                        </Col>

                        <Col className='col-12 col-md-2' >
                            <MInput id="InscricaoMunicipal" type="text" title="Inscrição Municipal" />
                        </Col>
                    </>
                }
            </Row>
            <Row>
                <Col className="col-12 col-md-12">
                    <MInput id="Nome" type="text" title="Nome" required />
                </Col>
                <Col className="col-12 col-md-12">
                    <MInput id="Responsavel" type="text" title="Responsável" required />
                </Col>
            </Row>
            <Row>
                {
                    tipoCadastro == "Sub-Revenda" && //TODO: campo obrigatorio
                    <>
                        <Col className='col-12 col-md-6' >
                            <MInput id="Revenda" type="selectSearch"
                                title="Revenda"
                                url={API_URL + "/revendedor/ListarRevendedores"}
                                params={v => ({ TermoDaBusca: v })}
                                onResult={res => res?.resultado?.map(z => ({ value: z.id, text: z.nome }))}
                                onSelectItem={e=> carregarProdutos(e)}
                                placeholder="Digite o nome da revenda..." />
                            <a href="javascript:window.open(location.href)">(+) Cadastrar novo</a>
                        </Col>
                    </>
                }

                {
                    tipoCadastro == "Revenda" &&
                    <Col className='col-12 col-md-6'>
                        <MInput id="Indicador" type="selectSearch"
                            url={API_URL + "/revendedor/ListarRevendedores"}
                            params={v => ({ TermoDaBusca: v })}
                            onResult={res => res?.resultado?.map(z => ({ value: z.id, text: z.nome }))}
                            title="Indicador"
                            onSelectItem={e=> carregarProdutos(e)}
                            placeholder="Digite o nome do indicador..." />
                        <a href="javascript:window.open(location.href)">(+) Cadastrar novo</a>
                    </Col>
                }
            </Row>
            <hr />
            <strong>ENDEREÇO</strong>
            <div id="bloco_endereco">
                <Row>
                    <Col className='col-6 col-md-2' >
                        <MInput name="Endereco.Cep" required type="mask" mask="99999-999" title="Cep" onBlur={e => pesquisacep(e.target.value)} />
                    </Col>
                    <Col className='col-12 col-md-6'>
                        <MInput id="logradouro" required name="Endereco.logradouro" type="text" title="Logradouro" />
                    </Col>
                    <Col className='col-4 col-md-1'>
                        <MInput id="Endereco.EndNumero" required type="text" title="Número" />
                    </Col>
                    <Col className='col-8 col-md-3' >
                        <MInput id="Endereco.Complemento" type="text" title="Complemento" />
                    </Col>
                </Row>
            </div>
            <Row>
                <Col className='col-12 col-md-6' >
                    <MInput id="bairro" name="Endereco.Bairro" required type="text" title="Bairro" />
                </Col>
                <Col className='col-12 col-md-4' >
                    <MInput id="cidade" name="Endereco.Cidade" required type="text" title="Cidade" />
                </Col>
                <Col className='col-4 col-md-1' >
                    <MInput id="uf" name="Endereco.Uf" required type="text" title="UF" />
                </Col>
            </Row>
            <hr />
            <strong>CONTATO</strong>
            <Row>
                <Col className='col-8 col-md-3'>
                    <MInput name="Contato.Telefone" type="mask" mask="(99)99999-9999" required title="Telefone Contato" onBlur={e => normalizaTelefone(e)} />
                </Col>
                <Col className='col-12 col-md-9'>
                    <MInput id="Contato.Email" type="email" title="Email Corporativo" required />
                </Col>
            </Row>
            <hr />
            <strong>DADOS BANCÁRIOS</strong>
            <Row>
                <Col className='col-12 col-md-4'>
                    <MInput id="Conta.Banco" dataType="int"
                        useValueOnSearch
                        type="selectSearch"
                        url={API_URL + "/banco/ListarBancos"}
                        params={v => ({ textoPesquisa: v })}
                        onSelectItem={e => selecionaBanco(e)}
                        title="Banco" 
                        placeholder="Digite o nome do banco..."
                        onResult={res => res.map(z => ({ value: z.id, text: `${z.codigo} - ${z.nome}`, codigo: parseInt(z.codigo) }))}
                    />
                </Col>
                <Col className='col-12 col-md-2'>
                    <MInput id="Conta.Tipo" dataType="int" type="select" data={tipoContaList} title="Tipo Conta" onSelect={e => setConta(e)} />
                </Col>
                {
                    tipoConta >= 3 &&
                    <>
                        <Col className='col-12 col-md-2'>
                            <MInput name="Conta.Pix" type="mask" mask={pixFilter} title={tipoContaList.filter(x => x.value == tipoConta)[0].text} />
                        </Col>
                    </>
                }
                {
                    tipoConta < 3 &&
                    <>
                        <Col className='col-5 col-md-2'>
                            <MInput type="text" title="Agência" id="Conta.Agencia" />
                        </Col>
                        <Col className='col-2 col-md-1'>
                            <MInput type="text" title="Digit." id="Conta.AgenciaDigito" />
                        </Col>
                        <Col style={{ display: (mostraOperacao ? "block" : "none") }} className='col-3 col-md-1'>
                            <MInput type="text" title="Operação" id="Conta.Operacao" />
                        </Col>
                        <Col className='col-8 col-md-3'>
                            <MInput type="text" title="C/C" id="Conta.ContaCorrente" />
                        </Col>
                    </>
                }

            </Row>
            <hr />
            <strong>PRODUTOS</strong>
            <table className='table table-striped'>
                <thead>
                    <tr>
                        <th>Produto</th>
                        <th>Comissão</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        produtosComissoes.map(x =>
                            <>
                                <tr key={x.produtoId}>
                                    <td style={{ paddingTop: 25 }} >
                                        <MInput type="checkbox"
                                            id={`produto_${x.produtoId}`}
                                            name="produto[]"
                                            onSelect={e => oculta(e.target.checked, x.produtoId)} value={x.produtoId} title={x.nomeProduto} /></td>
                                    <td>
                                        {
                                            ocultos.indexOf(x.produtoId) > -1 &&
                                            <div >
                                                <MInput type="select"
                                                    name="tabela[]"
                                                    id={`comissionamento_${x.produtoId}`}
                                                    data={x.comissoes.map(q => ({ value: q.id, text: q.nomeTabela }))} />
                                            </div>
                                        }

                                    </td>
                                </tr>
                            </>)
                    }
                </tbody>
            </table >

            <hr />
            <Row>
                <Col className='col-6 col-md-3'>
                    <MInput 
                        id="SituacaoRevendedorId" 
                        type="select" 
                        data={props.situacaoEmpresa} 
                        title="Situação"
                        onResult={res => res.map(z => ({ value: z.id, text: z.nome }))}
                     />
                </Col>
                <Col className='col-12 col-md-9'>
                    <MInput id="ApiKey" type="text" data={props.situacaoEmpresa} title="API Key" />
                </Col>
            </Row>

            <hr />
            <div style={{ position: "fixed", bottom: "5px", height: "60px", backgroundColor: "white", textAlign: "right", width: "100%" }}>

                <div style={{ width: "100%", paddingRight: "28%" }}>
                    <div style={{ float: "right" }}>
                        <Button onClick={() => salvar()}>
                            <i className="fa fa-save"></i>
                            Salvar
                        </Button>
                        <Button onClick={e => cancelar()} className="btn-secondary">
                            Fechar
                        </Button>
                    </div>
                </div>
            </div>

            <div style={{ height: 400 }}>

            </div>
        </form >
    </>
}
